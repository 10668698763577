.jeba_report_card {
  /* height: 120px; */
  color: black;
  background-color: #fff;
  padding: 1.2rem 1.6rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.8rem;
  transition: all 0.3s ease-in-out;
}
.jeba_report_card:hover {
  cursor: pointer;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.jeba_report_card:hover .jeba_report_card__heading {
  color: #0053e2;
}
.jeba_report_card__heading {
  font-size: 1.8rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jeba_report_card__heading button {
  color: #30e584;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
  padding: 8px;
  background-color: transparent;
}
.jeba_report_card__heading button:hover {
  background-color: #eafcf3;
}

.jeba_report_card__main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 5px;
}

.jeba_report_card__items h6 {
  text-transform: capitalize;
  color: #5a5a5a;
}
.jeba_report_card__values h6 {
  text-transform: capitalize;
  color: #3d3c3c;
}

.jeba_report_card__detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jeba_report_card__amount h6 {
  font-size: 1.4rem;
  font-weight: 500;
}

.jeba_report_card__percentage p span {
  font-size: 1.2rem;
  font-weight: 700;
}

.minus {
  color: #d93b3b;
}
.plus {
  color: #30e584;
}
