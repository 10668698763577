@media screen and (min-width: 480px) {
  .build_credit {
    width: 1000px;
    min-height: 85vh;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

@media screen and (max-width: 480px) {
  .build_credit {
    margin: 2.2rem auto;
  }
}
