.progressbar__wrapper {
  width: 210px;
  height: 150px;
  border-radius: 10px;
  background: #fff;
  padding: 2.2rem 2.5rem;
  margin: 0 0 1.2rem 0;
}
.progressbar {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.progressbar__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.8rem;
}
.progressbar__title {
  color: #000;
  font-family: Gilroy-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.progressbar__button {
  width: 32px;
  height: 32px;
  border-radius: 25px;
  background: var(--Primary-1, #0053e2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressbar__button svg {
  width: 24px;
  height: 24px;
}
.progressbar__button svg path {
  fill: var(--Text-Color-2-White, #fff);
}
