.button {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 88.889% */
  border: none;
}

.deposit {
  background-color: #fff;
  width: 100%;
  padding: 1.5rem 2.2rem;
  display: grid;
  grid-template-columns: minmax(10rem, auto);
  margin-left: 0.8rem;
  border-radius: 10px;
  margin-bottom: 1.2rem;
}
.deposit h4 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 1.2rem 0;
}
.deposit p {
  color: #000;
  /* Body Text */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
