.money_tracker {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
}

/* .buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  margin: 2.4rem 0;
} */

.button {
  background-color: #ffc321;
  padding: 8px 20px;
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #000;
  border: none;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
}
