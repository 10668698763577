/** Reusable classes*/
.user_dashboard__wrapper {
  margin: 2rem 0;
}

.user_dashboard h4 {
  font-size: 1.8rem !important;
}

.user_dashboard__button {
  /* border-radius: 4px; */
  /* border: 1px solid var(--Neutral-Color2, #8f8ca8); */
  /* background: var(--Text-Color-2-White, #fff); */
  padding: 1.2rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 1.6rem;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  width: 100%;
}

.user_dashboard__button:hover {
  color: #fff;
  background: #0053e2;
  border-color: #0053e2;
}

.user_dashboard__action__button__icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  /* color: #0053E2; */
  background: var(--Neutal-3, #e8ebf2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_dashboard__button:hover .user_dashboard__action__button__icon svg {
  color: #0053e2;
}
/** End of Reusable classes*/

.user_dashboard {
  margin-left: 0.8rem;
}
.user_balance {
  width: 300px;
  max-width: 100%;
  height: 130px;
  padding: 2.2rem;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
}
.user_dashboard h2 {
  margin: 0 0 1.2rem 0;
}
.user_balance__currency {
  color: #000;
  font-family: "Rubik", sans-serif;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 0.2rem;
}
.user_balance__amount {
  color: #000;
  /* font-family: Gilroy-Bold; */
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.user_balance__amount svg {
  font-size: 1.5rem;
}
.view_balance {
  position: absolute;
  border: none;
  background-color: transparent;
  right: 2.2rem;
}
.view_balance svg {
  width: 25px;
  height: 25px;
  color: #0053e2;
}
.user_dashboard__welcome,
.user_dashboard__actions {
  width: 100%;
  padding: 3rem;
  border-radius: 10px;
  background: var(--Text-Color-2-White, #fff);
}
.user_dashboard__welcome h4 {
  text-transform: capitalize;
}
.user_dashboard__buttons {
  margin-top: 2rem;
}

.user_dashboard__action__buttons {
  padding-top: 20px;
}
.app_download__section {
  border-radius: 10px;
  background: var(--Text-Color-2-White, #fff);
  padding: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app_download__section h4 {
  font-size: 2.4rem;
}

.app_download__section p {
  line-height: 1.8rem;
}
.app_download__button {
  padding: 1rem 3rem;
  border: none;
  border-radius: 25px;
  background: var(--Primary-1, #0053e2);
  color: var(--Text-Color-2-White, #fff);
  /* font-family: Gilroy-Medium; */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 88.889% */
  margin-top: 3rem;
}
.user_dashboard__button__invitations__badge {
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #28c951;
}
.user_dashboard__quick__setting__button {
  width: 100%;
  border-radius: 10px;
  background: var(--Text-Color-2-White, #fff);
  border: none;
  padding: 3rem 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_dashboard__quick__setting__button__inner {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Primary-1, #0053e2);
  background: var(--Text-Color-2-White, #fff);
  padding: 2rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.user_dashboard__quick__setting__button__inner__icon {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin-bottom: 2rem;
  background-color: var(--Neutal-3, #e8ebf2);
  color: #0053e2;
  font-size: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .user_balance {
    width: 100%;
  }
}
