.product_price {
  font-size: 1.8rem;
  font-weight: 600;
  color: #ffc724;
}

.add_to_cart {
  border-radius: 50px;
  border: 2px solid #1976d295;
  background: none;
  padding: 4px 14px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  transition: all 0.1s ease-in;
  color: #1976d2f9;
  width: 100%;
  margin-top: 1.5rem;
}

.add_to_cart:hover {
  border: 2px solid #1976d295;
  background: #1976d2f9;
  color: #fff;
}

.goback_btn {
  margin-bottom: 1.5rem;
}
