.jeba_modal__content {
  padding: 0 2.5rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal.module.css */

/* Overlay (backdrop) */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.customModal {
  background:white;
  border-radius: 30px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

/* Size variant */
.modalLg {
  max-width: 800px;
}

/* Header */
.modalHeader {
  padding: 20px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.primaryHeader {
  background: rgba(0, 83, 226, 0.8);
  color: white;
}

.dangerHeader {
  background: rgba(220, 53, 69, 0.8);
  color: white;
}

.modalTitle {
  width: 100%;
  text-align: center;
  font-size: 1.875rem;
  font-weight: bold;
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
}

/* Body */
.modalBody {
  background: rgba(255, 255, 255, 0.3);
  padding: 10px 30px 30px 30px ;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.jebaModalContent {
  color: #333;
  line-height: 1.6;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .customModal {
    max-width: 90%;
  }
  .modalBody {
    padding: 20px;
  }
  .modalTitle {
    font-size: 1.5rem;
  }
}