.navbar {
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  background: white;
  transition: all 0.3s ease;
}

.navbar:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title{
  color:#0053E2;
}

.container {
  width: 100%;
  padding: 0 1rem;
}

@media (min-width: 640px) {
  .container {
    padding: 0 1.5rem;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0 5rem;
  }
}

.navContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7rem;
}

.logoContainer {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.logoContainer:hover {
  transform: scale(1.05);
}

.title {
  display: flex;
  flex-direction: column;
}

.logoGroup {
  transition: all 0.3s ease;
}

.logoText {
  transition: color 0.2s ease;
}

.logoGroup:hover .logoText {
  color: #0053E2;
}

.slogan {
  font-size: 8px;
  font-weight: 800;
  text-align: center;
  color: #f97316;
  transition: transform 0.2s ease;
}

.logoGroup:hover .slogan {
  transform: translateY(-2px);
}

.desktopMenu {
  display: none;
  align-items: center;
  gap: 2rem;
}

@media (min-width: 768px) {
  .desktopMenu {
    display: flex;
  }
}

.navItem {
  position: relative;
  cursor: pointer;
}

.navLink {
  position: relative;
  color: black;
  transition: all 0.2s ease;
  text-decoration: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.navLink.active{
  color: #0053E2;
}

.navLinkHover {
  color: #0053E2;
}

.navLinkActive {
  color: #f97316; /* Changed from #0053E2 (blue) to #f97316 (orange) */
  font-weight: 600;
}



.dropdownTrigger {
  color: black;
  transition: all 0.2s ease;
}

.navItem:hover .dropdownTrigger {
  color: #0053E2;
}

.dropdownIcon {
  font-size: 0.75rem;
}

.dropdownItem {
  display: block;
  width: 100%;
  text-align: left;
  color: black;
  text-decoration: none;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #0053E2;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.underlineActive {
  transform: scaleX(1);
}

.buttonGroup {
  display: flex;
  gap: 1rem;
}

.loginButton {
  padding: 6px 3rem;
  border: 2px solid #0053E2;
  color: #0053E2;
  border-radius: 9999px;
  background: white;
  transition: all 0.3s ease;
  font-size: 1.5rem;
  cursor: pointer;
}

.loginButton:hover {
  background: #0053E2;
  color: white;
  transform: translateY(-2px);
}

.registerButton {
  padding: 6px 2rem;
  color: black;
  border-radius: 9999px;
  background: #fbbf24;
  transition: all 0.3s ease;
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (min-width: 768px) {
  .registerButton {
    display: block;
  }
}

.registerButton:hover {
  background: #f59e0b;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mobileMenu {
  display: block;
}

@media (min-width: 768px) {
  .mobileMenu {
    display: none;
  }
}

.menuButton {
  color: #4b5563;
  transition: color 0.2s ease;
}

.menuButton:hover {
  color: #0053E2;
}

.drawer {
  transition: all 0.3s ease;
}

.drawerContent {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
}

.drawerItem {
  padding: 0.5rem 0;
  color: black;
  transition: all 0.2s ease;
  text-decoration: none;
  font-size: 1.5rem;
  cursor: pointer;  
}

.drawerItem:hover {
  color: #0053E2;
  padding-left: 0.5rem;
}

/* Mobile Drawer Active State */
.drawerItemActive {
  color: #f97316; /* Changed from #0053E2 (blue) to #f97316 (orange) */
  font-weight: 600;
}

.submenu {
  padding: 0.5rem 0;
}

.submenuTitle {
  font-weight: 600;
  color: #374151;
}

.submenuItem {
  display: block;
  padding: 0.5rem 0 0.5rem 1rem;
  color: black;
  transition: all 0.2s ease;
  text-decoration: none;
}

.submenuItem:hover {
  color: #0053E2;
  padding-left: 1.5rem;
}

.drawerButton {
  padding: 6px 2rem;
  color: black;
  border-radius: 9999px;
  background: #fbbf24;
  transition: all 0.3s ease;
  margin-top: 1rem;
  font-size: 1.5rem;
}

.drawerButton:hover {
  background: #f59e0b;
  transform: scale(1.05);
}

.drawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

/* Style for the close icon (provided by Ant Design) */
:global(.ant-drawer-close) {
  position: absolute;
  right: 0;
  margin-right: 1.4rem;
  top: 30px;
  transform: translateY(-50%);
  color: #4b5563;
  transition: color 0.2s ease;
}

:global(.ant-drawer-close:hover) {
  color: #0053E2;
}

/* Ensure the logo stays on the left */
.logoContainer {
  margin-right: auto;
}

/* Adjust drawer content padding if needed */
.drawerContent {
  padding: 1rem;
}