.jeba_invitations__container {
  width: 100%;
  border-radius: 10px;
  background: var(--Text-Color-2-White, #fff);
  padding: 1.5rem 3rem;
  margin-top: 2.2rem;
}
.jeba_invitations__container__header {
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #e8e8e8;
}
.jeba_invitations {
  margin-top: 1.6rem;
}
