.filters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  margin: 2rem 0;
  gap: 10px;
}

.filters__search {
  height: 4rem;
  width: 26rem;
  padding: 0.8rem 1.2rem;
  margin-right: 1.8rem;
  font-size: 1.6rem;
  background-color: #e8ebf2;
  border: 1px solid #8f8ca8;
  border-radius: 10px;
}

.filters__search__refresh {
  display: flex;
  align-items: center;
  width: 70%;
}

.filters__search__refresh input:focus {
  border-color: #0053e2;
  outline: none;
  width: 100%;
}

/* .filters__add__new{
  width: fit-content;
} */

.filters__refresh__button {
  height: 40px;
  width: 40px;
  background-color: #0053e2;
  border-radius: 100%;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters__add__new__button {
  height: 40px;
  /* min-width: 190px; */
  background-color: #ffc321;
  border-radius: 50px;
  border: none;
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  color: #000;
}

.filters__refresh__button svg,
.filters__add__new__button svg {
  height: 20px;
  width: 20px;
  color: #fff;
}

.filters__add__new {
  color: var(--Primary-2, #000);
  /* font-family: Gilroy-Medium; */
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
}

@media screen and (max-width: 480px) {
  .filters__search {
    height: 4rem;
    width: 100%;
    padding: 0.8rem 1.2rem;
  }
  .filters {
    display: block;
  }
  .filters__refresh__button {
    margin-top: 20px;
  }
}
