.message_item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.message {
  background-color: #0053e2;
  color: #fff;
  padding: 1.2rem;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  position: relative;
  width: fit-content;
  max-width: 60%;
}
.message {
  background-color: #0053e2;
  color: #fff;
  padding: 1.2rem;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  position: relative;
  width: fit-content;
  max-width: 60%;
}
.message_info span {
  padding-right: 0.8rem;
  font-size: 1.4rem;
}
/* .message_info {
  text-align: right;
} */
.name {
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: capitalize;
}
.time,
.period {
  font-size: 1rem;
  color: #3e3b3bdf;
}
/* .message p {
  z-index: 9;
} */

/* .message::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: red;
  top: 0;
  border-radius: 2px;
  transform: rotate(45deg);
} */
