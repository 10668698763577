.product_card {
  background: #ffffff;
  padding: 12px;
  transition: all 0.2s ease-in-out;
  height: 100%;
  border-radius: 10px;
}
.product_card:hover {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  /* transform: scale(1.02); */
}
.product_image {
  position: relative;
}

.product_image img {
  transition: transform 0.3s ease-in-out;
}

.product_image:hover img {
  transform: scale(0.95);
}

.product_image:hover .overlay {
  opacity: 1;
}
.overlay {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000074;
}
.product_image img {
  width: 100%;
}
.product_discount__section {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product_discount {
  width: 35px;
  height: 35px;
  background-color: #ffc724;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  font-family: "Rubik";
}
.product_text {
  background-color: #ffc724;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  font-family: "Rubik";
  padding: 0.2rem 0.6;
  text-align: center;
}
.product_title {
  margin: 1.2rem 0;
  text-align: center;
}
.product_title h3 a {
  text-transform: capitalize;
  text-decoration: none;
}
.product_description p {
  font-size: 1.2rem;
  line-height: 16px;
  text-align: center;
}
.product_card__bottom {
  margin-top: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}
.add_to_cart {
  border-radius: 50px;
  border: 2px solid #1976d295;
  background: none;
  padding: 4px 14px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  transition: all 0.1s ease-in;
  color: #1976d2f9;
  width: 100%;
}

.add_to_cart:hover {
  border: 2px solid #1976d295;
  background: #1976d2f9;
  color: #fff;
}
.product_price {
  color: green;
  text-align: center;
}
.no_flicker {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
