.card__wrapper {
  /* width: 300px; */
  border-radius: 10px;
  /* border: 1px solid var(--Neutral-Gray1, #38414c); */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.05);
  background: var(--Text-Color-2-White, #fff);
  margin-bottom: 1.2rem;
  max-height: 200px;
  overflow: hidden;
}
.card__wrapper:hover {
  cursor: pointer;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.12);
}
.card__content {
  width: 100%;
  margin: 0 auto;
}
.card__balance__title {
  color: #000;
  font-family: Gilroy-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card__balance__amount {
  font-family: Gilroy-Bold;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  margin-top: 1.2rem;
  margin-bottom: 1.5rem;
}

.card__balance__amount__currency {
  font-size: 16px;
  font-family: Gilroy-Medium;
}

.card__details {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.card__period span {
  color: #000;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card__percentage {
  color: var(--Secondary1, #30e584);
  font-family: Gilroy-Bold;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card__percentage svg {
  width: 1.8rem;
  height: 1.8rem;
}
