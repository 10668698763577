.group__container {
  width: 100%;
  border-radius: 10px;
  background: var(--Text-Color-2-White, #fff);
  padding: 1.5rem 2rem;
  /* margin: 2.2rem; */
}

.group__navigation {
  border: 1px solid #8f8ca8;
  border-style: none none solid none;
}
.navigation {
  width: 100%;
}
.list__items {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 5rem;
  margin: 0;
  overflow: auto;
}
.list__items::-webkit-scrollbar {
  display: none;
}
.list__item {
  list-style: none;
  white-space: nowrap;
}

.list__item__link {
  color: var(--Text-Color1--Black, #000);
  font-family: "Rubik", sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  transition: all 0.1s ease-in;
  display: block;
  padding: 0.8rem 2rem;
}

.list__item__link:hover,
.list__item__link.active {
  color: #0053e2;
  border: 1px solid #0053e2;
  border-style: none none solid none;
  z-index: 1;
}

.group__sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}

.group__sort__button {
  display: flex;
  gap: 0.5rem;
}
.sort__button {
  color: var(--Primary-2, #def);
  font-family: "Rubik", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: var(--Primary-1, #0053e2);
  padding: 0.6rem 1.8rem;
}

.sort__button svg {
  width: 2rem;
  height: 2rem;
  margin-left: 8rem;
}
.sort__button svg path {
  width: 2.4rem;
  height: 2.4rem;
  fill: var(--Primary-2, #def);
}
/* .group_content {
  width: 80%;
} */
@media screen and (max-width: 480px) {
  .list__items {
    gap: 0;
  }
  .sort__button svg {
    margin-left: 0;
  }
}
