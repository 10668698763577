.login__page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 87vh;
  /* background-color: #fff; */
}
.login__page__header {
  margin: 2rem 0;
}
.title {
  color: var(--primary-1, #0053e2);
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.login__form__column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 20px;
  grid-template-rows: minmax(1fr, auto);
}

.jeba_tagline span {
  font-size: 1.2rem;
  margin-right: 0.4rem;
  font-weight: bold;
}

.jeba_tagline span:first-child,
.jeba_tagline span:nth-child(2) {
  color: #ffc321;
}
.jeba_tagline span:last-child,
.jeba_tagline span:nth-child(3) {
  color: #0053e2;
}

@media screen and (max-width: 780px) {
  .login__page {
    width: 80%;
    margin: 0px auto;
    /* padding: 1px 20px; */
  }
  .banner {
    display: none;
  }

  .login__page__header,
  .jeba_tagline {
    text-align: center;
  }
  .login__form__column {
    grid-column: span 2;
  }
}
