.product_cart {
  padding: 0.8rem 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #00000055;
  border-style: none none solid none;
}
.product_info_section {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.product_info {
  display: flex;
  gap: 0.8rem;
}
.product_img {
  max-width: 80px;
}
.product_img img {
  width: 100%;
}

.product_actions {
  margin-top: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove_button button {
  background: none;
  border: none;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #d93b3b;
  transition: all 0.3s ease-in-out;
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remove_button button svg {
  width: 28px;
  height: 28px;
}
.remove_button button:hover {
  background: #f7d8d8;
}

.increase_quantity_form {
  display: flex;
  /* gap: 0.1rem; */
  margin: 0;
}
.increase_quantity_form input {
  width: 40px;
  border-radius: 0;
  margin: 0;
  padding: 0.4rem 0.8rem !important;
  text-align: center;
}
.increase_btn,
.decrease_btn {
  border: none;
  min-width: 30px;
  background-color: #0053e2;
  color: #fff;
  font-size: 1.8rem;
}
.increase_btn {
  border-radius: 0 5px 5px 0;
}
.decrease_btn {
  border-radius: 5px 0 0 5px;
}
