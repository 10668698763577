.random_number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  border-radius: 100px;
}
.icon {
  height: 20px;
  width: 20px;
  font-size: 1.5rem;
}
.open_box {
  font-size: 2.2rem;
  display: flex;
  flex-direction: column;
  color: #0053e2;
}

.rounds_wrapper {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: #0053e2;
  position: relative;
}

.rounds {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: yellow;
  position: absolute;
}
