.subscription_form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.subscription_form__control {
  width: 45%;
}

.subscription_form__control label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.subscription_form__control input {
  font-size: 1.2rem;
  font-weight: 500 !important;
}
