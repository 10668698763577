/* .build_credit {
  background: #fefefe;
  border-radius: 10px;
  padding: 3rem 2.5rem;
  position: relative;
  min-width: 250px;
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
}
.build_credit_image {
  width: 40px;
}
.build_credit_image img {
  width: 100%;
}

.build_credit_title p {
  color: #a9a9a9;
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
  line-height: normal;
  font-weight: 500;
}
.build_credit_cost h3 {
  font-weight: 700;
  color: #04335f;
}

.build_credit_includes {
  width: 100%;
  margin: 1.2rem auto;
  display: flex;
  justify-content: center;
}
.build_credit_includes ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.build_credit_includes ul li {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}
.build_credit_includes ul li svg {
  color: rgb(19, 209, 19);
  margin-right: 5px;
}

.build_credit_button button {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background: #2222ff;
  color: #fff;
  border: 1px solid transparent;
  font-size: 16px;
  font-style: normal;
  padding: 0px 20px;
  font-weight: 400;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
}

.build_credit_button_90 button {
  background: green;
}
.build_credit_button_180 button {
  background: #2222ff;
}
.build_credit_button_365 button {
  background: yellow;
}
.build_credit_button_90 button:hover {
  background-color: #fff;
  color: green;
  border: 1px solid green;
}
.build_credit_button_180 button:hover {
  background-color: #fff;
  color: #2222ff;
  border: 1px solid #2222ff;
}
.build_credit_button_365 button:hover {
  background-color: #fff;
  color: yellow;
  border: 1px solid yellow;
}

.build_credit_button button:hover {
  background-color: #0053e2;
}

.clip_path {
  background: #2222ff;
  width: 45px;
  height: 65px;
  clip-path: polygon(100% 1%, 100% 100%, 46% 85%, 0 99%, 0 1%);
  position: absolute;
  top: -12.5px;
  right: 25px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clip_path_90 {
  background: green;
}
.clip_path_180 {
  background: #2222ff;
}
.clip_path_365 {
  background: yellow;
}

.clip_path h4 {
  font-size: 16px;
  line-height: 1;
  margin: 0;
  font-weight: 600;
}
.clip_path p {
  font-size: 10px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.build_credit::before {
  content: "";
  background: #2222ff;
  height: 12px;
  width: 12px;
  position: absolute;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  right: 70px;
  top: -12px;
}

.build_credit_90::before {
  background: green;
}
.build_credit_180::before {
  background: #2222ff;
}
.build_credit_365::before {
  background: yellow;
} */

.buildCreditContainer {
  background: #fefefe;
  border-radius: 10px;
  padding: 2rem 1.5rem;
  position: relative;
  min-width: 250px;
  width: 100%;
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* Header styling */
.tableHeader {
  text-align: center;
  margin-bottom: 1rem;
}

.tableHeader h3 {
  font-weight: 700;
  color: #04335f;
  margin-bottom: 1rem;
}

.headerImage {
  width: 40px;
  height: 40px;
}

/* Table styling */
.daysTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
}

.tableCell {
  padding: 10px;
  border: 1px solid #e5e5e5;
  text-align: center;
  font-size: 1.6rem;
  color: black;
}

/* Features list */
.featuresList {
  width: 100%;
  margin: 1.2rem 0;
}

.featuresList ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.featuresList ul li {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.featuresList ul li svg {
  color: rgb(19, 209, 19);
  margin-right: 5px;
}

/* Button styling */
.buttonContainer {
  width: 100%;
  margin: 1rem 0;
}

.actionButton {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  color: #fff;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
}

/* Color variations for different plans */
.buildCreditContainer:has([data-days="90"]) .actionButton {
  background: green;
}

.buildCreditContainer:has([data-days="180"]) .actionButton {
  background: #2222ff;
}

.buildCreditContainer:has([data-days="365"]) .actionButton {
  background: yellow;
}

/* Hover effects */
.buildCreditContainer:has([data-days="90"]) .actionButton:hover {
  background-color: #fff;
  color: green;
  border: 1px solid green;
}

.buildCreditContainer:has([data-days="180"]) .actionButton:hover {
  background-color: #fff;
  color: #2222ff;
  border: 1px solid #2222ff;
}

.buildCreditContainer:has([data-days="365"]) .actionButton:hover {
  background-color: #fff;
  color: yellow;
  border: 1px solid yellow;
}

.actionButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Period label */
.periodLabel {
  text-align: center;
  padding: 10px;
  font-weight: 500;
  color: #a9a9a9;
  text-transform: uppercase;
  font-size: 12px;
}

/* Clip path decoration
.buildCreditContainer::before {
  content: "";
  background: #2222ff;
  height: 12px;
  width: 12px;
  position: absolute;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  right: 70px;
  top: -12px;
} */

.buildCreditContainer:has([data-days="90"])::before {
  background: green;
}

.buildCreditContainer:has([data-days="180"])::before {
  background: #2222ff;
}

.buildCreditContainer:has([data-days="365"])::before {
  background: yellow;
}

.build_credit_button button {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background: #2222ff;
  color: #fff;
  border: 1px solid transparent;
  font-size: 16px;
  font-style: normal;
  padding: 0px 20px;
  font-weight: 400;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
}

.build_credit_button_90 button {
  background: green;
}
.build_credit_button_180 button {
  background: #2222ff;
}
.build_credit_button_365 button {
  background: yellow;
}
.build_credit_button_90 button:hover {
  background-color: #fff;
  color: green;
  border: 1px solid green;
}
.build_credit_button_180 button:hover {
  background-color: #fff;
  color: #2222ff;
  border: 1px solid #2222ff;
}
.build_credit_button_365 button:hover {
  background-color: #fff;
  color: yellow;
  border: 1px solid yellow;
}

.build_credit_button button:hover {
  background-color: #0053e2;
}