.jeba__table__wrapper {
  width: 100%;
  overflow: auto;
  border-radius: 10px;
}
.jeba__table__wrapper::-webkit-scrollbar {
  display: none;
}

.jeba__table {
  border-collapse: collapse;
  width: 100%;
  text-transform: capitalize;
  border-radius: 10px !important;
  display: table;
}
.jeba__table th {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  background-color: #fff;
  /* color: white; */
  font-size: 1.4rem;
  font-family: "Rubik", sans-serif;
  line-height: 24px;
  font-weight: 500;
  white-space: nowrap;
}
.jeba__table th:first-child {
  border-radius: 5px 0 0 0;
}
.jeba__table th:last-child {
  border-radius: 0 5px 0 0;
}

.jeba__table td,
.jeba__table th {
  border-bottom: 1px solid #ddd;
  padding: 1.5rem 0.8rem;
}

.jeba__table tbody {
  background-color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #707070;
}

/* .jeba__table tr:nth-child(even) {
  background-color: #f2f2f2;
} */

.jeba__table tr:hover {
  background-color: #ddd;
}

.jeba__table__navigation {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.8rem;
}
.jeba__table__navigation__button {
  min-width: 6rem;
  border: none;
  background: none;
  font-family: "Rubik", sans-serif;
  font-size: 1.4rem;
  line-height: 16px;
  padding: 0.8rem;
  color: #0053e2;
  display: flex;
  align-items: center;
}
