.card {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 18px;
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.imageWrapper {
  flex: 0 0 150px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  min-height: 120px;
}

.contentWrapper {
  flex: 1;
  padding: 15px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* From .news__title */
.title {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0 0 10px 0;
  color: #333;
  text-transform: capitalize; 
}

.content {
  font-size: 1rem;
  color: #666;
  margin: 0 0 10px 0;
  line-height: 1.5;
}

/* From .news__content p button */
.readMoreBtn {
  border: none;
  background-color: #fff;
  color: rgb(54, 123, 227);
  font-size: 1.2rem;
  font-style: italic;
  padding: 0;
  margin-left: 5px;
  cursor: pointer;
  transition: color 0.2s;
}

.readMoreBtn:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* From .news__details */
.details {
  display: flex;
  gap: 18px;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: #999999;
  font-weight: 700;
}

.author {
  font-weight: 700;
}

.date {
  font-style: italic;
  font-weight: 700;
}

/* From .download */
.downloadLink {
  color: red;
  font-style: italic;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.2s;
}

.downloadLink:hover {
  color: darkred;
  text-decoration: underline;
}

/* Animation from @keyframes myAnim */
@keyframes myAnim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Apply animation to content (optional) */
.content {
  animation: myAnim 1s ease-in-out;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card {
    flex-direction: column;
  }

  .imageWrapper {
    flex: none;
    width: 100%;
    height: 200px;
  }
}