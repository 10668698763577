.mobile {
  background: var(--primary-1, #0053e2);
  width: 250px;
  max-width: 100%;
}
.sidebar_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.sidebar_header button {
  color: #fff;
  background: none;
  border: none;
  font-size: 2rem;
}
@media screen and (min-width: 1300px) {
  .mobile {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .mobile {
    width: 250px !important;
    transition: all 0.5s ease-in-out;
  }
}
