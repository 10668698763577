.transaction__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
  border-bottom: 1px solid #000;
}
.transaction__details h5 {
  color: #000;
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.transaction__details p {
  color: #000;
  text-align: center;
  font-family: Gilroy-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.transaction__amount {
  color: var(--Secondary1, #30e584);
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.next__arrow__icon {
  text-align: right;
}
