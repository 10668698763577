@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 62.5%;
  font-family: "Poppins", sans-serif;
}
body {
  background: #e8ebf2 !important;
  font-family: "Poppins", sans-serif;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f8fafc;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #053d72, #0053e2);
  border-radius: 5px;
}
/* End scrollbar styles */

a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
h1 {
  /* font-family: Gilroy-Bold; */
  font-family: "Poppins", sans-serif;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
h3,
span,
h6,
button {
  font-family: "Poppins", sans-serif;
}

h4 {
  /* Header 4 */
  /* font-family: Gilroy-Bold; */
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-style: bold;
  font-weight: 500 !important;
  line-height: normal;
}

h5 {
  color: var(--Neutral-Gray1, #38414c);

  /* Header 5 */
  /* font-family: Gilroy-Medium; */
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
p {
  /* font-family: Gilroy-Regular; */
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

input::placeholder {
  font-family: "Poppins", sans-serif;
}
button:disabled,
input:disabled,
label:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
form {
  width: 100%;
}

.custom-tooltip {
  background-color: red;
}

.row {
  margin-right: 0 !important;
}
.step-progress {
  font-family: "Poppins";
}
.step-progress ul li:last-of-type {
  display: none;
}

.mt-12 {
  margin-top: 24px;
}
.mb-12 {
  margin-bottom: 24px;
}

@media screen and (max-width: 480px) and (min-width: 360px) {
}
@media screen and (max-width: 1280px) and (min-width: 480px) {
}
@media screen and (max-width: 1440px) and (min-width: 1280px) {
}
@media screen and (min-width: 1440px) {
}
