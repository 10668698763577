.form__button button {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  border: 1px solid var(--primary-1, #0053e2);
  background: var(--text-color-2-white, #fff);
  color: var(--text-color-1-black, #000);
  display: flex;
  flex-direction: nowrap;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 2.4rem;
  /* Button Text */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.form__button button {
  cursor: pointer;
}
