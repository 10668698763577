.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  /* margin: 8px auto; */
  position: relative;
  color: #0053e2;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 -2px,
      -14px 0 0 -2px,
      -38px 0 0 -2px;
  }
  25% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 -2px,
      -14px 0 0 -2px,
      -38px 0 0 2px;
  }
  50% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 -2px,
      -14px 0 0 2px,
      -38px 0 0 -2px;
  }
  75% {
    box-shadow:
      14px 0 0 2px,
      38px 0 0 -2px,
      -14px 0 0 -2px,
      -38px 0 0 -2px;
  }
  100% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 2px,
      -14px 0 0 -2px,
      -38px 0 0 -2px;
  }
}
