.mobile_transfer {
  padding: 2rem 2rem;
  border-radius: 10px;
  background-color: #fff;
  max-width: 50%;
  margin: auto;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.mobile_transfer label {
  font-size: 1.4rem;
  font-weight: 700;
  color: #5c5c5f;
  margin: 1.4rem 0 0.6rem 0;
}

@media (max-width: 768px) {
  .mobile_transfer {
    max-width: 100%;
  }
}
