.MemberReportsCard {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  padding: 1.4rem 1.8rem;
  transition: all 0.3s ease-in;
}

.MemberReportsCard:hover {
  cursor: pointer;
  border: 1px solid #0053e2;
}

.MemberReportsCard_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MemberReportsCard__image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 3px solid #0053e2;
}
.MemberReportsCard__image img {
  width: 100%;
}
.MemberReportsCard_view {
  position: relative;
}

.MemberReportsCard_view svg {
  font-size: 24px;
}

.MemberReportsCard_view button {
  display: none;
  width: fit-content;
  position: absolute;
  background: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1.2rem 1.6rem;
  z-index: 9;
  left: -95px;
  top: -12px;
  transition: all 0.1s ease-in;
}
.MemberReportsCard_view button:hover {
  background-color: #c9d6ff;
}
.MemberReportsCard_view:hover > button {
  display: block;
}

.MemberReportsCard_details__items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.2rem;
  gap: 0.8rem;
}

.MemberReportsCard_details__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  max-width: 100%;
  border-radius: 10px;
  padding: 1.2rem 1.4rem;
  margin: 0.4rem 0;

  background: #c9d6ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #e2e2e2,
    #c9d6ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #e2e2e2,
    #c9d6ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.MemberReportsCard_details__item__value {
  font-size: 1.6rem;
  font-weight: 700;

  margin-bottom: 0;
}

.MemberReportsCard_details__item__name {
  margin: 0;
  text-transform: capitalize;
}
