.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.4rem;
  border-bottom: 1px solid #e0e0e0;
  /* box-shadow: 0 15px 0px rgba(0, 0, 0, 0.08); */
  background-color: #fff;
}
.title {
  color: var(--primary-1, #0053e2);
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  /* line-height: normal; */
}

.landing_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
}

@media screen and (max-width: 880px) {
}

@media screen and (max-width: 480px) {
  .login_button {
    display: none;
  }
}
