.cash_round_card {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #dedede;
  /* padding: 15px; */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.cash_round_card:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.cash_round_card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  display: none;
}
.cash_round_card_footer a {
  font-size: 18px;
  text-transform: capitalize;
}

.cash_round_card_header_link svg {
  width: 20px;
  height: 20px;
  color: #000;
}

.cash_round_card_header_link {
  text-decoration: none;
}
