.members_slide__buttons {
  display: flex;
}
.members_slide__buttons button {
  margin-left: 0.8rem;
  border-radius: 100px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 24px;
  height: 24px;
  color: #0053e2;
  font-weight: 600;
}

.members_slide__buttons button:hover {
  background-color: #0053e276;
  /* background-color: #e2e2e2; */
  color: #fff;
}
