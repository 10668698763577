.dashboard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.8rem;
  padding-right: 3.5rem;
  padding-left: 22rem;
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 6.4rem;
  z-index: 99;
  box-shadow: 0 10px 8px -3px rgba(0, 0, 0, 0.12);
}
.hamburger button {
  font-size: 2rem;
  background: none;
  border: none;
}
.greeting h4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  text-transform: capitalize;
  margin-bottom: 0;
}
.search {
  position: relative;
  display: flex;
  align-items: center;
  width: 60%;
}
.search input {
  border-radius: 10px;
  background: #e8ebf2;
  border: none;
  /* width: 400px; */
  min-width: 100%;
  height: 40px;
  padding: 0 3.6rem;
}
.search input:focus {
  outline: none;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.12);
}
.search svg {
  position: absolute;
  height: 2rem;
  width: 2rem;
  left: 1.2rem;
}

.notification {
  display: flex;
  position: relative;
  background-color: transparent;
  border: none;
}
.notification svg {
  width: 2.8rem;
  height: 2.8rem;
}

.count {
  position: absolute;
  right: -0.5rem;
  bottom: -0.1rem;
  display: flex;
  align-items: center;
  height: 1.8rem;
  width: 1.8rem;
  color: #fff;
  background-color: var(--Primary-1, #0053e2);
  border-radius: 2.5rem;
  padding: 0.8rem;
}
.avatar {
  width: 50px;
  height: 50px;
  /* background-color: blue; */
  border-radius: 5rem;
  background-image: url(../../../assets/images/user.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1300px) {
  .dashboard__header {
    padding-right: 1.5;
    padding-left: 2rem;
  }
}

@media screen and (max-width: 780px) {
  .search {
    display: none;
  }
}
@media screen and (min-width: 1300px) {
  .hamburger {
    display: none;
  }
}
