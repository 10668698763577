.active {
  border: 1px solid #0053e2;
  border-style: none none solid none;
  font-weight: bold;
  color: #0959a4;
}

.nav_section{
  width: 80%;
}

.nav_buttons{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav_section button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 0.4rem;
  font-size: 2rem;
  color: #0053e2;
  display: none;
}
@media screen and (max-width: 480px) {
  .nav_section button {
    display: flex;

  }

  .nav_section {
    position: relative;
    width: 50%;
  }
  .nav_buttons {
    opacity: 0;
    position: absolute;
    top: 3.5rem;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 99;
    width: 350px;
    border-radius: 10px;
    transition: 0.3s opacity ease-in-out;
    text-shadow: 0px 0px 0px black;
  }
}
