.order_card {
  background-color: #f9fafb;
  width: 300px;
  max-width: 100%;
  padding: 1.2rem;
  border-radius: 8px;
  margin: 0.4rem;
}

.order_card h4 {
  color: #8e8e8e;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
  text-transform: capitalize;
}
.order_card p {
  font-weight: bold;
  font-size: 1.8rem;
  color: #0053e2;
}
@media screen and (max-width: 480px) {
  .order_card {
    width: 100%;
  }
}
