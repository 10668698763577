/* src/CircularLayout.css */
.rounds-container {
  width: 100%;
  /* max-width: 1280px; */
  display: flex;
  justify-content: center;
  margin-top: 6rem;
}
.circle-container {
  position: relative;
  width: 200px;
  height: 200px;
  border: 1px solid #0053e2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-item {
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  font-family: 2rem;
  color: #fff;
  font-weight: 600;
}
.circle-item[title]:hover::after {
  content: attr(title);
  font-size: 14px;
  color: white;
  background-color: #0053e2;
  padding: 5px;
  border-radius: 5px;
}
