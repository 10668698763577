.sidebar {
  width: 100%;
  height: 100vh;
  z-index: 100;
  overflow-y: auto;
  background-color: #0053e2;
}
.sidebar__title {
  color: var(--Text-Color-2-White, #fff);
  /* font-family: Gilroy-Bold; */
  font-size: 24px;
  /* font-style: normal; */
  font-weight: bold;
  line-height: normal;
  padding: 1.5rem 3rem;
}
.separator {
  margin: 4rem 0;
  height: 1px;
  width: 100%;
  background-color: var(--Text-Color-2-White, #fff);
}

@media screen and (max-width: 1300px) {
  .sidebar {
    display: none;
    transition: all 1s ease-in-out;
  }
}

@media screen and (max-width: 480px) {
  .separator {
    margin: 2rem 0;
    height: 1px;
    width: 100%;
    background-color: var(--Text-Color-2-White, #fff);
  }
  .sidebar__title {
    padding: 0.8rem 2rem;
  }
}
