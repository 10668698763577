.increase_quantity_form {
  display: flex;
  /* gap: 0.1rem; */
  /* justify-content: center; */
  margin: 0;
  width: 100%;
}
.increase_quantity_form input {
  width: 40px;
  border-radius: 0;
  margin: 0;
  padding: 0.4rem 0.8rem !important;
  text-align: center;
}
.increase_btn,
.decrease_btn {
  border: none;
  min-width: 30px;
  background-color: #0053e2;
  color: #fff;
  font-size: 1.8rem;
}
.increase_btn {
  border-radius: 0 5px 5px 0;
}
.decrease_btn {
  border-radius: 5px 0 0 5px;
}
