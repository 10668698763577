.statcard__wrapper {
  border-radius: 8px;
  background: var(--Neutal-3, #e8ebf2);
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem;
  gap: 1.2rem;
  margin-bottom: 1.2rem;
}
.statcard__wrapper:hover {
  cursor: pointer;
}
.statcard__icon__container {
  border-radius: 4px;
  background: var(--Neutal-3, #e8ebf2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: var(--Secondary1, #30e584);
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon svg {
  width: 24px;
  height: 24px;
  color: var(--Text-Color-2-White, #fff);
}
.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item {
  color: #000;

  /* Body Text Medium */
  font-family: Gilroy-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1.5rem;
}
.rate {
  color: #000;

  /* Body Text */
  font-family: Gilroy-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
