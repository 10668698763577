.description {
  white-space: pre-line; /* Preserve line breaks */
  text-align: justify;
}

.truncated {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 1 line */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: justify;
}

.expanded {
  white-space: pre-line; /* Show all lines */
  text-align: justify;
}
