.jeba_invitation_card__wrapper {
  padding-bottom: 0.8rem;
  border: 1px solid #e8e8e8;
  /* margin: 0.8rem; */

  border-radius: 10px;
}
.jeba_invitation_card {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: start;
  padding: 10px;
  /* text-align: center; */
  border-bottom: 1px solid #e8e8e8;
}
.jeba_invitation_card__sender,
.jeba_invitation_card__text,
.jeba_invitation_card__group {
  display: inline-block;
  margin-right: 0.8rem;
  text-align: left;
}
.jeba_invitation_card__text {
  font-style: italic;
}

.jeba_invitation_card__message {
  padding: 10px;
}

.jeba_invitation_card__sender,
.jeba_invitation_card__group {
  font-size: 1.6rem;
  font-weight: bold;
}
.jeba_invitation_card__button {
  border: none;
  border-radius: 10px;
  padding: 0.6rem 1.6rem;
  /* margin-left: 1.2rem; */
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  transition: all 0.3s ease-in-out;
}

.jeba_invitation_card__button svg {
  width: 30px;
  height: 25px;
}

.accept {
  border: 1px solid #0053e2;
  color: #0053e2;
  background-color: #fff;
}
.accept:hover {
  background-color: #0053e2;
  color: #fff;
}

.decline {
  color: #ff0000;
  background-color: #fff;
}
.decline:hover {
  background-color: #ff0000;
  color: #fff;
}

/** STATUS STYLES*/

.pending {
  color: #ffc724;
  background-color: #fff;
}
.pending:hover {
  background-color: #ffc724;
  color: #fff;
}
.approved {
  color: #30e584;
  background-color: #fff;
}
.approved:hover {
  background-color: #30e584;
  color: #fff;
}
/** END STATUS STYLES*/
