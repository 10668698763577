.group__card {
  /* border-radius: 10px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border: 1px solid var(--Neutral-Color2, #dedede);
  margin: 0.5rem 0;
  transition: all 0.3s ease-in-out; */
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #dedede;
  /* padding: 15px; */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.group__card:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.group_header_link {
  text-decoration: none;
}
.group__details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1.2rem;
  padding-top: 10px;
}
.group__icon {
  width: 3rem;
  height: 3rem;
  background-color: #0053e2;
  border-radius: 25px;
  padding: 0.2rem;
  text-align: center;
  display: none;
}
.group__icon svg {
  width: 2.4rem;
  height: 2.4rem;
  color: #fff;
}

.group__name a {
  text-decoration: none;
}
.group__name a:link,
.group__name a:visited,
.group__name h4:hover {
  color: #0053e2;
}

.group__name h4 {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.group__name p {
  color: #575454;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6rem; /* 114.286% */
}

.group__created__date span {
  color: #343131;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 10px;
  line-height: 1.6rem; /* 114.286% */
}

@media screen and (max-width: 480px) {
  .group__card {
    padding: 1rem 1.5rem;
    height: fit-content;
  }

  .group__name h4 {
    font-size: 1.4rem;
  }
}
