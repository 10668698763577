/* Loading container */
.loading-container {
  display: flex;
  min-height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/* Main container */
.join-campaign-container {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

/* Card styling */
.join-campaign-card {
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

/* Header styling */
.campaign-header h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.campaign-header p {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
}

/* Button container */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

/* Button styling */
.join-button {
  background-color: #0053e2;
  border-color: #0053e2;
  color: white;
  width: 200px;
}

.back-button {
  width: 200px;
}

/* Responsive design */
@media (max-width: 768px) {
  .join-campaign-card {
    padding: 20px;
  }

  .campaign-header h2 {
    font-size: 20px;
  }

  .campaign-header p {
    font-size: 14px;
  }

  .button-container {
    flex-direction: column;
  }

  .join-button,
  .back-button {
    width: 100%;
  }
}
