.jeba_saving_account {
  border-radius: 15px;
  padding: 2.5rem;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;

  /* background: #e1eec3;
  background: -webkit-linear-gradient(to right, #f05053, #e1eec3);
  background: linear-gradient(to right, #f05053, #e1eec3); */

  background: #c9d6ff;
  background: -webkit-linear-gradient(to right, #e2e2e2, #c9d6ff);
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);
  margin: 1.2rem 0;
}
.jeba_saving_account__type {
  font-size: 1.8rem;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
  line-height: 1.2rem;
  color: #03294c;
  text-transform: capitalize;
}
.jeba_saving_account__holder {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  color: #021f39;
  text-transform: capitalize;
}
.jeba_saving_account__details {
  margin: 1.2rem 0;
  display: flex;
  align-items: center;
}
.jeba_saving_account__interest {
  color: #d93b3b;
}
.jeba_saving_account__interest,
.jeba_saving_account__status {
  margin-right: 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}
.jeba_saving_account__status {
  color: green;
}
.jeba_saving_account__interest svg,
.jeba_saving_account__status svg {
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
}
.jeba_saving_account__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e8ebf2;
  border-style: solid none none none;
  padding-top: 1.2rem;
  color: #021426;
}
.jeba_saving_account__Balance span,
.jeba_saving_account__number span {
  font-size: 1.4rem;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
}
