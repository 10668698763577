.statistics-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  cursor: pointer;
}

.statistics-card:hover {
  /* transform: translateY(-4px); */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}
