.section__wrapper {
  padding: 3rem;
  border-radius: 10px;
  background: var(--primary-1, #0053e2);
}

.section__text {
  color: #fff;
}

.section__text h2 {
  /* width: 320px; */

  /* Header 2 */

  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}
.section__text p {
  /* Body Text */
  font-family: Gilroy-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 1rem 0;
  text-align: center;
}
.section__image {
  width: 100%;
  /* max-width: 320px; */
  height: 320px;
  position: relative;
  padding: 5rem 0;
  border-radius: 10px;
  background-image: url('../../assets/images/loginpage.avif');
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: cover;
}
.section__inner__image {
  position: absolute;
  right: -12rem;
  width: 100%;
  max-width: 180px;
  height: 220px;
  border-radius: 10px;
  background: #fff;
}

@media screen and (max-width: 880px) {
  .section__inner__image {
    right: -40rem;
  }
}

@media screen and (max-width: 480px) {
  .section__inner__image {
    left: 8rem;
  }
}
