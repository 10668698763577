.filter__wrapper {
  margin-top: 2.2rem;
}
.filter__header h4 {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter__options {
  background-color: #fff;
  margin: 0.5rem 0;
  padding: 1.5rem 2.2rem;
  border-radius: 0.8rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
}

.filter__option h6 {
  color: #000;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.4rem;
}

.filter__option select {
  width: 80%;
  height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
}
.filter__option select:focus {
  outline: 2px solid rgba(0, 0, 0, 0.12);
}
