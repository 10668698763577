.group__button {
  /* display: block; */
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  /* font-family: Gilroy-Medium; */
  text-transform: capitalize;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  border: 1px solid var(--Primary-1, #0053e2);
  text-decoration: none !important;
  text-align: center;
  background-color: #fff;
  color: #0053e2;
  white-space: nowrap;
}

.active {
  background-color: #0053e2;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .group__button {
    height: fit-content;
    width: fit-content;
    min-width: 150px;
  }
}
