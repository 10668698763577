.share_card {
  /* width: 400px; */
  border-radius: 10px;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  padding: 1.2rem 0;
  margin: 1rem 0;
}
.share_card__header,
.share_card__main,
.share_card__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
/* .share_card__header {
  border-bottom: 1px solid #84b3df;
  padding-bottom: 1.2rem;
  margin-bottom: 1.2rem;
} */
.share_card__header h2 {
  font-size: 1.8rem;
  color: #053d72;
  margin: 0;
  text-transform: capitalize;
}
.share_card__header form input {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
}

.share_card__header button {
  min-width: 98px;
  color: #075cab;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: none;
  border-radius: 50px;
  background-color: #cee0f2;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: capitalize;
  padding: 4px 12px;
  text-transform: uppercase;
  white-space: nowrap;
}

.share_card__main h3 {
  font-size: 1.6rem;
  font-weight: 700;
  color: #053d72;
}
.share_card__main h3 span {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.share_card__footer h5 {
  color: #999999;
}
