.account_transfer {
  padding: 0 2rem 2rem;
  background-color: #fff;
  border-radius: "10px";
  max-width: 50%;
  margin: auto;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.account_transfer__form_control label {
  font-size: 1.4rem;
  font-weight: 700;
  color: #5c5c5f;
  margin: 1.4rem 0 0.6rem 0;
}
.account_transfer__sending {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
  color: #000 !important;
}
.receiver_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.4rem 0 0.6rem 0;
}

.select_receiver__button {
  font-size: 1.2rem;
  font-weight: 700;
  color: #0053e2;
  background-color: #fff;
  border: none;
  display: flex;
  align-items: center;
  gap: 1px;
}
.select_receiver__button svg {
  width: 40px;
  height: 28px;
}
.receiver {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  padding: 1.2rem 1.4rem;
  background-color: #e8ebf2;
  border-radius: 10px;
}
.receiver_image {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 5px solid #0053e2;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
}
.receiver_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.receiver_name {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: capitalize;
}
.receiver_email {
  font-size: 1.4rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  .account_transfer {
    max-width: 100%;
  }
}
