.button {
  background: none;
  border: none;
}

.link {
  display: inline-block;
  font-size: 14px;
  font-family: "Rubik";
  text-decoration: none;
  padding: 10px 16px;
  width: 100%;
}
.link:hover {
  background-color: aliceblue;
}
