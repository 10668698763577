.dashboard {
  height: 100%;
  max-width: 100%;
  width: 100%;
  display: flex;
  /* gap: 0.5rem; */
}

.dashboard__content {
  width: 100%;
  margin-top: 6.4rem;
  margin-left: 199px;
  padding: 1.2rem;
  max-width: calc(100% - 199px);
}

.dashboard__sidebar {
  width: 199px;
  height: 100vh;
  position: fixed;
  z-index: 999;
  left: 0;
  background: var(--primary-1, #0053e2);
}

@media screen and (max-width: 1300px) {
  .dashboard__content {
    width: 100%;
    margin-left: 0;
    max-width: 100%;
  }
  .dashboard__sidebar {
    display: none;
    transition: all 1s ease-in-out;
  }
}

@media screen and (max-width: 480px) {
  .dashboard__content {
    padding: 1rem;
    max-width: 100%; 
  }
}

.dashboard__content h2 {
  color: #000;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  text-transform: capitalize;
  margin: 2rem 0;
}