.list__item {
  width: 100%;
  list-style: none;
}
.list__link:hover,
.active {
  background: #1976d2;
  border-left: 0.5rem solid #fff;
  cursor: pointer;
}

.list__link {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0 1rem 2rem;
  transition: all 0.3s ease-in-out;
}

.list__link h6 {
  color: var(--Text-Color-2-White, #fff);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.list__link svg {
  width: 24px;
  height: 24px;
  color: var(--Text-Color-2-White, #fff);
}

.submenu {
  padding-left: 20px;
  list-style: none;
  margin-top: 5px;
}

.submenu__item {
  padding: 5px 0;
}

.dropdown__icon {
  margin-left: auto;
  font-size: 12px;
}

.list__link {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: inherit;
}

.list__item {
  cursor: pointer;
}
