.message_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 1rem;
  width: 100%;
  font-size: 1.4rem;
  border: 1px solid #0053e2;
}
.chat_form form label {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
  font-weight: 500;
}
.message_body:focus {
  outline: #0053e2;
}
