.loan_list_card {
  background: #e7f8ff;
  padding: 1.6rem;
  border-radius: 10px;
}
.loan_list_card__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
}
.loan_list_card__status {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 199, 36);
  border-radius: 50px;
  font-family: Gilroy-Bold;
  font-size: 10px;
  text-transform: capitalize;
  padding: 8px;
}
.loan_list_card__type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #b2bcc5;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}
.loan_list_card__type span {
  font-size: 10px;
  font-family: Gilroy-Regular;
  color: #053e74;
}
.loan_list_card__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loan_list_card__details__amount {
  font-family: Gilroy-Bold;
  font-size: 1.6rem;
  font-weight: 900;
  color: #0053e2;
}
.loan_list_card__details__button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
  border-radius: 50px;
  background-color: #6b7881;
  font-size: 12px;
  font-family: Gilroy-Regular;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0.6rem 2.2rem;
  transition: all 0.4s ease-in-out;
}
.loan_list_card__details__button:hover {
  background-color: #0053e2;
}
