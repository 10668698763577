.footer {
  padding: 3rem 0; /* py-12 */
  background-color: #0053e2;
  color: white;
  width: 100%;
}

.container {
  width: 90%;
  margin: auto;
  padding: 0 1rem; /* px-4 */
}

@media (min-width: 640px) {
  .container {
    padding: 0 1.5rem; /* sm:px-6 */
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0 2rem; /* lg:px-8 */
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr; /* Default: single column for mobile */
  gap: 2rem; /* gap-8 */
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: 2fr 1fr 1fr 1fr; /* Desktop: 2/5 for first column, 1/5 for others */
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  display: flex;
  flex-direction: column;
}

.slogan {
  font-size: 8px;
  font-weight: 800;
  text-align: center;
  color: #ffc321;
}

.text {
  color: white;
  font-size: 14px;
  text-align: justify;
}

.heading {
  font-weight: 600;
  margin-bottom: 1rem; /* mb-4 */
  color: white;
  font-size: 18px;
}

.linkGroup {
  display: flex;
  flex-direction: column;
  gap: 0.25rem; /* gap-1 */
}

.footerLink {
  font-size: 14px;
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footerLink:hover {
  color: #ffc321;
  transform: translateX(5px);
}

.socialGroup {
  display: flex;
  gap: 1rem; /* space-x-4 */
  margin-top: 1rem;
}

.socialLink {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
  background-color: #949597;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.socialLink:hover {
  color: #0053e2;
  transform: scale(1.1);
}

.divider {
  margin: 2rem 0; /* my-8 */
  border-top: 1px solid #374151;
}

.copyright {
  text-align: center;
  font-size: 1rem;
  color: white;
}