.form__wrapper {
  width: 100%;
}
.form__wrapper h2 {
  color: #000;

  /* Header 2 */

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 3rem;
}
.form__wrapper p {
  color: #000;
  /* Header 2 */
  /* font-family: Gilroy-Medium; */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
}

.form__control {
  text-align: left;
  margin: 0.8rem 0;
  width: 100%;
}

.form__control label {
  color: #000;
  /* font-family: Gilroy-Medium; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.form__input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
}

.form__control input,
.form__control select {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--primary-1, #0053e2);
  background: #fff;
  color: #000;
  /* font-family: Gilroy-Regular; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding-left: 5.6rem;
}
.form__control select,
.jeba_select__container {
  width: 98.5%;
  border-radius: 10px;
  border: 1px solid var(--primary-1, #0053e2);
  background: #fff;
  /* font-family: Gilroy-Regular; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.form__control input::placeholder,
.form__control select::placeholder {
  font-family: "Rubik", sans-serif;
  color: #8f8ca8;
}
.form__control textarea {
  width: 98.5%;
  height: 10.8rem;
  border-radius: 10px;
  border: 1px solid var(--primary-1, #0053e2);
  background: #fff;
  color: #000;
  /* font-family: Gilroy-Regular; */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
  padding: 1.2rem;
}
.form__control input:focus,
.form__control textarea:focus,
.form__control select:focus {
  border: 2px solid var(--primary-1, #0053e2);
  outline: var(--primary-1, #0053e2);
}

.input__error {
  border: 2px solid red !important;
  color: red;
}
.input__error__container {
  color: red;
  font-size: 1.4rem;
}
.form__input__icon {
  position: absolute;
  left: 1rem;
}
.form__input svg {
  width: 24px;
  height: 24px;
}
.form__input svg path {
  fill: #8f8ca8;
}

.password__show {
  position: absolute;
  right: 1.5rem;
}
.password__show:hover {
  cursor: pointer;
}
.form__top__button {
  display: flex;
  gap: 1.8rem;
}
.form__login button {
  width: 98%;
  height: 40px;
  border-radius: 50px;
  background: #0053e2;
  border: none;
  color: #fff;
  /* font-family: Gilroy-Medium; */
  font-size: 16px;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center;
  font-weight: 400;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
}

.form__top__button button.active {
  background: #fff !important;
  color: #0053e2;
  cursor: pointer;
  border: 1px solid var(--primary-1, #0053e2);
}
.form__login span {
  color: #0053e2;
  /* font-family: Gilroy-Regular; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: block;
  text-align: right;
  margin-top: 1.2rem;
}

.form__separator {
  color: #000;
  /* font-family: Gilroy-Regular; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.form__login__google {
  margin: 1rem auto;
  /* width: 98%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  padding-right: 10px;
}
.form__login__google button {
  width: 98%;
  height: 40px;
  border-radius: 50px;
  border: 1px solid var(--primary-1, #0053e2);
  background: var(--text-color-2-white, #fff);
  color: var(--text-color-1-black, #000);
  display: flex;
  flex-direction: nowrap;
  align-items: center;
  justify-content: center;

  /* Button Text */
  /* font-family: Gilroy-Medium; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.form__register {
  text-align: center;
}
.form__register span {
  color: #000;

  /* Body Text */
  /* font-family: Gilroy-Regular; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
.signup {
  color: var(--primary-1, #0053e2) !important;
}
.signup:hover {
  cursor: pointer;
}

.required {
  color: red;
  font-size: 1.2rem;
  margin-left: 2px;
}

@media screen and (max-width: 480px) {
  .form__login__google {
    flex-wrap: wrap;
  }
  .form__wrapper {
    text-align: center;
  }
}
