/* .hero_section {
  width: 40%;
  padding: 20px;
  margin: 20px auto;
  padding-right: 40px;
  padding-left: 40px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

@media screen and (max-width: 880px) {
  .hero_section {
    width: 100%;
    padding: 0px;
    gap: 0px;
    margin: 20px auto;
  }
} */

.hero_section {
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
}

/* Small screens */
@media screen and (max-width: 640px) {
  .hero_section {
    padding: 0.5rem;
    gap: 1rem;
    width: 100%;
  }
}

/* Medium screens and up */
@media screen and (min-width: 768px) {
  .hero_section {
    width: 50%;
    padding: 1.5rem;
  }
}

/* Large screens */
@media screen and (min-width: 1024px) {
  .hero_section {
    width: 45%;
    padding: 2rem;
  }
}
