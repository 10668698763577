.group__single__wrapper {
  margin-top: 2.2rem;
}

.goback_btn {
  margin-bottom: 10px;
}
.group_single__image {
  width: 100%;
  height: 250px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px 10px 0 0;
  position: relative;
}

.group_single__image__profile {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 4px solid #0053e2;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 2rem;
}
.group_single__head {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  position: relative;
}

/* .group_single__desc_container {
  display: flex;
  justify-content: space-between;
} */

.group_single__desc_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.switch__dropdown_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 10px 0px;
}

.group_single__details {
  padding: 1rem 1rem;
}
.group__single__header {
  margin-bottom: 1.2rem;
}
.group__single__heading {
  line-height: 1.8rem;
  margin: 0 !important;
}
.group__single__header span {
  color: #706f6f;
  display: inline-block;
  font-size: 1.2rem !important;
  font-weight: 400;
  line-height: 1.8rem;
  /* margin-right: 0.5rem !important; */
}

.group_single__financial__details {
  width: 100%;
  display: flex;

  flex-wrap: wrap;
  gap: 10px;
}

.group__single__amount {
  font-weight: 800 !important;
  /* font-family: Gilroy-Bold !important; */
  color: #0053e2 !important;
  padding-right: 1.2rem;
  /* border-style: none solid none none; */
  /* border: 1px solid red; */
}

.group_single {
  margin: 2rem 0;
  /* display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.8rem; */
}
.group_single_info {
  width: 100%;
}

.group_name {
  color: #000;
  /* Header 4 */
  font-family: "Rubik", sans-serif;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.group_description {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  margin: 0.5rem 0;
}
.group_description span .group__single__heading span {
  margin-left: 40px;
}
.group__single__buttons {
  display: flex;
  gap: 1.8rem;
  margin: 1.8rem 0;
  justify-content: center;
  align-items: center;
}
.group__single__nav__buttons {
  display: flex;
  gap: 0.8rem;
  width: 100%;
  max-width: 1300px;
  overflow-x: auto;
}
.group__single__nav__buttons::-webkit-scrollbar {
  display: none;
}
.group__single__buttons__display__setting {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column: 6 / 6;
}

.button__icon {
  width: 32px;
  height: 32px;
  border: none;
}
.button__icon svg {
  width: 26px;
  height: 26px;
}

.button__icon__active {
  background-color: #fff;
  border: 1px solid var(--Primary-1, #0053e2);
  border-radius: 4px;
}

.group__chat {
  border-radius: 10px;
  background: var(--Text-Color-2-White, #fff);
  padding: 1.8rem 2.5rem;
}

.group__chat__container__sender,
.group__chat__container__receiver {
  width: 100%;
}
.group__chat__sender,
.group__chat__receiver {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.group__chat__sender__image,
.group__chat__receiver__image {
  width: 32px;
  height: 32px;
  border-radius: 40px;
}

.group__chat__sender__name,
.group__chat__receiver__name {
  color: #000;
  font-family: "Rubik", sans-serif;
  font-size: 1.4rem;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.group__chat__message {
  margin-top: 0.5rem;
  margin-left: 4rem;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Color2, #8f8ca8);
  background: var(--Text-Color-2-White, #fff);
  padding: 0.8rem 1.2rem;
}

.group__chat__message p,
.group__chat__message__receiver p {
  color: #000;
  font-family: "Rubik", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.group__chat__receiver {
  justify-content: flex-end;
}
.group__chat__message__receiver {
  margin-top: 0.5rem;
  /* margin-right: 4rem; */
  border-radius: 4px;
  border: 1px solid var(--Neutral-Color2, #8f8ca8);
  background: var(--Text-Color-2-White, #fff);
  padding: 0.8rem 1.2rem;
}

.group__chat__form {
  margin: 1rem 0;
}
.group__chat__form__message textarea {
  padding: 1.2rem;
  width: 100%;
  min-height: 10rem;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Color2, #8f8ca8);
  background: var(--Text-Color-2-White, #fff);
}

.group__chat__form__message textarea::placeholder {
  color: var(--Neutral-Color2, #8f8ca8);

  font-family: "Rubik", sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.group__chat__form__message textarea:focus {
  outline: 1px solid var(--Primary-1, #0053e2);
}

.form__control__button {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .group__single__nav__buttons_cash {
    justify-content: center;
  }
  .group_single__desc_container {
    flex-direction: row;
    /* Switch to row layout for 3 columns */
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .group_single__desc_container > * {
    flex: 1 1 calc(33.333% - 1rem);
    /* Ensure 3 equal columns with spacing */
  }

  .switch__dropdown_container {
    display: flex;
    justify-content: flex-end;
  }
}
