.contribute_card {
  padding: 1.2rem;
  margin: 0.8rem 0.4rem;
  border-radius: 10px;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  background: #c9d6ff;
  background: -webkit-linear-gradient(to right, #e2e2e2, #c9d6ff);
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);
}

.contribute_card__heading {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}
.contribute_card__icon {
  color: #42c179;
  background-color: #e4f2ec;
  height: 50px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 0.8rem;
}
.contribute_card__icon svg {
  width: 100%;
  height: 100%;
}
.contribute_card__name h2 {
  margin: 0;
  padding: 0;
}
.contribute_card__name p {
  text-transform: capitalize;
}
.contribute_card__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.6rem;
}
.contribute_card__button button {
  border: none;
  background-color: #c9d6ff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0.6rem 2.2rem;
  transition: all 0.4s ease-in-out;
}
