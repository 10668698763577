.section {
  margin: 5rem 0;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 1.2rem;
  width: 100%;
  align-items: center;
}
.button {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 88.889% */
  border: none;
}
.button__blue {
  background: var(--Primary-1, #0053e2);
  color: var(--Text-Color-2-White, #fff);
  margin-right: 1.2rem;
}
.button__white {
  background: var(--Primary-1, #fff);
  color: var(--Text-Color-2-White, #0053e2);
}
.section__summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1.2rem;

  margin: 5rem 0;
}
.summary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.statistics {
  display: flex;
  justify-content: space-between;
}
.stat {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  width: 500px;
  height: 300px;
  border-radius: 10px;
  background: var(--Neutal-3, #e8ebf2);
  padding: 1.5rem;
}
.transactions {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
}
.transaction__summary {
  border-radius: 10px;
  background: var(--Neutal-3, #e8ebf2);
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 3rem;
}
.transaction__summary h2 {
  color: #000;

  /* Body Text Medium */
  font-family: Gilroy-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.savings {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
}
.statistics {
  display: grid;
  width: 100%;
  padding: 1.8rem;
  background-color: white;
  border-radius: 10px;
}
.statistics h4 {
  color: #000;
  font-family: Gilroy-Bold;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.statistics__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}
.barChart {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
}

.transactions__cards {
  display: grid;
}
.benefits {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0.8rem;
}
.deposit {
  background-color: #fff;
  width: 100%;
  padding: 1.5rem 2.2rem;
  display: grid;
  grid-template-columns: minmax(10rem, auto);
}
.deposit h4 {
  color: #000;
  font-family: Gilroy-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.deposit p {
  color: #000;
  /* Body Text */
  font-family: Gilroy-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
