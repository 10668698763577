.cart {
  display: flex;
  justify-content: center;
  position: relative;
}

.cart a {
  font-size: 1.8rem;
  text-decoration: none;
}

.cart a svg {
  width: 32px;
  height: 32px;
  margin-right: 0.6rem;
}
.cart_items {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ffc724;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #fff;
  font-size: 1.2rem;
  left: 36%;
  top: -8px;
}
