.wallet__buttons {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
}
.wallet__container {
  margin: 3rem 0.8rem 3rem 1.5rem;
  border-radius: 20px;
}

.wallet__transfer__quick__transfer,
.wallet__wallet__mobile__top_up {
  border-radius: 10px;
  background: #fff;
  padding: 2rem 1rem;
  min-height: 87vh;
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
}
.wallet__transfer__quick__transfer h4,
.wallet__wallet__mobile__top_up h4 {
   color: #757c8e;
  margin-bottom: 1rem;
}


form {
  margin-top: 0; 
  width: 100%; 
  max-width: 600px; 
}

.wallet__contact,
.wallet__add__contact {
  max-width: 100px;
  max-height: 100px;
}
.wallet__contact img {
  width: 100%;
}

.wallet__add__contact {
  border-radius: 10px;
  border: 1px solid var(--Primary-1, #0053e2);
  background: #fff;
  color: #0053e2;
  padding: 2rem;
  display: flex;
  justify-content: center;
}
.wallet__add__contact svg {
  color: var(--Primary-1, #0053e2);

  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* .wallet__wallet__mobile__top_up {
  margin-top: 1.8rem;
} */
.jeba_select__container {
  width: 98.5%;
  border-radius: 10px;
  border: 1px solid var(--primary-1, #0053e2);
  background: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
form {
  margin-top: 2rem;
}
form input,
form select {
  width: 100%;
  padding: 0.5rem 0.8rem;
  border-radius: 10px;
  border: 1px solid var(--Primary-1, #0053e2);
  background: var(--Text-Color-2-White, #fff);
  margin-right: 1.2rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

form input::placeholder {
  color: var(--Neutral-Gray1, #38414c);

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
form input:focus,
form select:focus {
  outline: 1px solid var(--Primary-1, #0053e2);
}

.form__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
  margin-top: 1.8rem;
  width:100%;
}
.form__buttons button {
  display: block;
  font-size: 1.6rem;
  text-transform: capitalize;
  padding: 0.6rem 1.2rem;
  border-radius: 10px;
  border: 1px solid var(--Primary-1, #0053e2);
  text-decoration: none !important;
  text-align: center;
  background-color: #fff;
  color: #0053e2;
  min-width: 100px;
}

button.active {
  background-color: #0053e2;
  color: #fff;
}
